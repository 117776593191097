import { makeAutoObservable, toJS } from "mobx";
import { Player } from "./Player";
import { GameStatus, ScoreSlot } from "../types";
import { Highscores } from "./Highscores";

export class GameState {
  public currentPlayer: Player | null = null;
  public players: Player[] = [];
  public status: GameStatus = GameStatus.Playing;
  public highscores = new Highscores();

  constructor() {
    makeAutoObservable(this);

    const existingState = localStorage.getItem("game-state");
    if (existingState) {
      try {
        const data = JSON.parse(existingState);
        this.deserialize(data);
        return;
      } catch (e) {
        console.warn(e);
      }
    }

    if (this.playerCount === 0) {
      this.addPlayer("Player 1");
      this.addPlayer("Player 2");
      this.setCurrentPlayer(this.players[0]);
    }
  }

  public addPlayer(name: string) {
    this.players.push(new Player(name));
    this.saveToLocalStorage();
  }

  public renamePlayer(player: Player, name: string) {
    player.setName(name);
    if (name === "") {
      this.removePlayer(player);
    }
    this.saveToLocalStorage();
  }

  public removePlayer(player: Player) {
    this.players.splice(this.players.indexOf(player), 1);
    this.saveToLocalStorage();
  }

  public freshGame() {
    this.players = [];
    this.addPlayer("Player 1");
    this.addPlayer("Player 2");
    this.currentPlayer = this.players[0];
    this.status = GameStatus.Playing;
    this.saveToLocalStorage();
  }

  public restartGame() {
    this.players.forEach((player) => {
      player.score.reset();
    });
    this.currentPlayer = this.players[0];
    this.status = GameStatus.Playing;
    this.saveToLocalStorage();
  }

  public setCurrentPlayer(player: Player | null) {
    this.currentPlayer = player;
  }

  public nextPlayer() {
    let mostMissing = this.players[0];
    for (let i = 0; i < this.players.length; i++) {
      const nextPlayer = this.players[i];
      if (nextPlayer.score.missingScores > mostMissing.score.missingScores) {
        mostMissing = nextPlayer;
        break;
      }
    }
    this.setCurrentPlayer(mostMissing);
    this.updateGameStatus();
  }

  private updateGameStatus(skipUpdate: boolean = false) {
    if (this.status === GameStatus.Over) return;
    for (let i = 0; i < this.players.length; i++) {
      if (this.players[i].score.missingScores > 0) {
        return;
      }
    }
    if (!skipUpdate) {
      this.highscores.addGame(
        this.players.map((player) => ({
          name: player.name,
          yatzy:
            player.score[ScoreSlot.Yatzy] !== null &&
            player.score[ScoreSlot.Yatzy] !== 0,
          total: player.score[ScoreSlot.Total],
        }))
      );
      this.highscores.saveToLocalStorage();
    }
    this.status = GameStatus.Over;
    this.currentPlayer = null;
  }

  public get playerCount() {
    return this.players.length;
  }

  public get firstPlace(): Player | undefined {
    return [...this.players].sort((a, b) => b.score.total - a.score.total)[0];
  }

  public get secondPlace(): Player | undefined {
    return [...this.players].sort((a, b) => b.score.total - a.score.total)[1];
  }

  public get thirdPlace(): Player | undefined {
    return [...this.players].sort((a, b) => b.score.total - a.score.total)[2];
  }

  public get isGameOver() {
    return this.status === GameStatus.Over;
  }

  public getSerializedState() {
    return toJS(this);
  }

  public deserialize(obj: any) {
    this.players = [];
    obj.players.forEach((player) => {
      const p = new Player(player.name);
      p.deserialize(player);
      this.players.push(p);
    });
    this.updateGameStatus(true);
    this.nextPlayer();
  }

  public saveToLocalStorage() {
    localStorage.setItem(
      "game-state",
      JSON.stringify(this.getSerializedState())
    );
  }
}
