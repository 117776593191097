import React from "react";
import useClasses from "react-use-classes";
import { DiceNumber } from "../types";
import "./Dice.scss";

export function Dice({
  number,
  size = "normal",
}: {
  number: DiceNumber;
  size?: "small" | "normal" | "large";
}) {
  return (
    <div
      className={useClasses("dice", {
        one: number === 1,
        two: number === 2,
        three: number === 3,
        four: number === 4,
        five: number === 5,
        six: number === 6,
        [size]: true,
      })}
    >
      <div className="dice-pip dice-pip-1"></div>
      <div className="dice-pip dice-pip-2"></div>
      <div className="dice-pip dice-pip-3"></div>
      <div className="dice-pip dice-pip-4"></div>
      <div className="dice-pip dice-pip-5"></div>
      <div className="dice-pip dice-pip-6"></div>
    </div>
  );
}
