import React from "react";
import { getLocal, setLocal } from "../util/localStorage";
import { useForceUpdate } from "../hooks/useForceUpdate";
import { isApp } from "../util/pwa";
import "./HomeScreenPrompt.scss";
import { MobileIcon } from "@radix-ui/react-icons";
import useClasses from "react-use-classes";

export function HomeScreenPrompt() {
  const show = getLocal("home-screen-prompt-seen", false) === false && !isApp();
  const forceUpdate = useForceUpdate();

  return (
    <div
      className={useClasses("home-screen-prompt", {
        show,
      })}
    >
      <MobileIcon className="home-screen-prompt-icon" />
      <div className="home-screen-prompt-text">
        Add Yatzi.app to your Home Screen for the most optimal experience.
      </div>
      <button
        className="home-screen-prompt-button"
        onClick={() => {
          setLocal("home-screen-prompt-seen", true);
          forceUpdate();
        }}
      >
        Okay
      </button>
    </div>
  );
}
