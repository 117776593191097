export function getLocal<T>(id: string): T | undefined;
export function getLocal<T>(id: string, defaultValue?: T): T;
export function getLocal<T>(id: string, defaultValue?: T): T {
  const data = localStorage.getItem(id);
  if (data) {
    try {
      const value = JSON.parse(data);
      return value;
    } catch (e) {
      console.warn(`getLocal: Could not parse data for "${id}"`);
      return defaultValue as T;
    }
  }
  return defaultValue as T;
}

export function setLocal<T>(id: string, value: T) {
  localStorage.setItem(id, JSON.stringify(value));
}
