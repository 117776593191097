import { makeAutoObservable } from "mobx";
import { getLocal, setLocal } from "../util/localStorage";

const HIDE_TOTAL_DURING_GAME = "settings/hide-total-during-game";

export class Settings {
  public hideTotalDuringGame = getLocal(HIDE_TOTAL_DURING_GAME, false);

  constructor() {
    makeAutoObservable(this);
  }

  setHideTotalDuringGame(value: boolean) {
    this.hideTotalDuringGame = value;
    setLocal(HIDE_TOTAL_DURING_GAME, this.hideTotalDuringGame);
  }
}
