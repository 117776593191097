import { observer } from "mobx-react-lite";
import React from "react";
import { useAppState } from "../models/store";
import useClasses from "react-use-classes";
import "./ClickCatchLayer.scss";

function ClickCatchLayerComponent() {
  const appState = useAppState();
  const enabled = !!appState.scoreSelection;
  return <div className={useClasses("click-catch-layer", { enabled })}></div>;
}

export const ClickCatchLayer = observer(ClickCatchLayerComponent);
