import { observer } from "mobx-react-lite";
import "./NameAutoComplete.scss";
import React, { useEffect, useMemo, useRef } from "react";
import { useAppState } from "../models/store";

function NameAutoCompleteComponent({
  currentName,
  setName,
  parentEl,
}: {
  currentName: string;
  setName(name: string): void;
  parentEl: HTMLDivElement | null;
}) {
  const appState = useAppState();
  const { playerList } = appState.gameState.highscores;
  const ref = useRef<HTMLDivElement>(null);

  const filteredList = useMemo(
    () =>
      playerList.filter((name) =>
        currentName.length > 0
          ? name.toLowerCase().startsWith(currentName.toLowerCase())
          : true
      ),
    [currentName]
  );

  const isSameAsCurrent =
    filteredList.length === 1 && filteredList[0] === currentName;
  const showList = filteredList.length > 0 && !isSameAsCurrent;

  useEffect(() => {
    if (!showList) return;

    if (ref.current && parentEl) {
      const bounds = parentEl.getBoundingClientRect();

      ref.current.style.top = `${bounds.bottom + 2}px`;
    }
  }, [parentEl, showList]);

  if (!showList) return null;

  return (
    <div ref={ref} className="name-auto-complete">
      {filteredList.map((name, index) => (
        <div
          key={index}
          className="name-auto-complete-name"
          onMouseDown={() => {
            setName(name);
          }}
        >
          {name}
        </div>
      ))}
    </div>
  );
}

export const NameAutoComplete = observer(NameAutoCompleteComponent);
