import useClasses from "react-use-classes";
import { useAppState } from "../models/store";
import React from "react";
import { HeaderPlayer } from "./HeaderPlayer";
import { ScoreSlot } from "../types";
import { ScoreCell } from "./ScoreCell";
import { observer } from "mobx-react-lite";

function PlayersComponent() {
  const appState = useAppState();
  const slots = Object.values(ScoreSlot) as ScoreSlot[];

  return (
    <div className="board-players">
      {appState.gameState.players.map((player, index) => (
        <div
          className={useClasses("board-players-player", {
            active: appState.gameState.currentPlayer === player,
            gold: appState.gameState.firstPlace === player,
          })}
          key={`${player.name}--${index}`}
        >
          <HeaderPlayer player={player} />
          {slots.map((key) => (
            <ScoreCell key={key} player={player} type={key} />
          ))}
        </div>
      ))}
    </div>
  );
}

export const Players = observer(PlayersComponent);
