import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useAppState } from "../models/store";
import useClasses from "react-use-classes";
import { DiceNumber, ScoreSlot } from "../types";
import { Dice } from "./Dice";
import { Player } from "../models/Player";
import { useClickOutside } from "react-haiku";
import "./ScoreSelector.scss";
import { clamp } from "../util/math";

function getScoreFromDice(dice: DiceNumber[]) {
  let total = 0;
  for (let i = 0; i < dice.length; i++) {
    total += dice[i];
  }
  return total;
}

function getAvailableOptionsPerSlot(slot: ScoreSlot): DiceNumber[][] {
  switch (slot) {
    case ScoreSlot.Ones:
      return [[1], [1, 1], [1, 1, 1], [1, 1, 1, 1], [1, 1, 1, 1, 1]];
    case ScoreSlot.Twos:
      return [[2], [2, 2], [2, 2, 2], [2, 2, 2, 2], [2, 2, 2, 2, 2]];
    case ScoreSlot.Threes:
      return [[3], [3, 3], [3, 3, 3], [3, 3, 3, 3], [3, 3, 3, 3, 3]];
    case ScoreSlot.Fours:
      return [[4], [4, 4], [4, 4, 4], [4, 4, 4, 4], [4, 4, 4, 4, 4]];
    case ScoreSlot.Fives:
      return [[5], [5, 5], [5, 5, 5], [5, 5, 5, 5], [5, 5, 5, 5, 5]];
    case ScoreSlot.Sixes:
      return [[6], [6, 6], [6, 6, 6], [6, 6, 6, 6], [6, 6, 6, 6, 6]];
    case ScoreSlot.OnePair:
      return [
        [1, 1],
        [2, 2],
        [3, 3],
        [4, 4],
        [5, 5],
        [6, 6],
      ];
    case ScoreSlot.TwoPairs:
      return [];
    case ScoreSlot.ThreeOfAKind:
      return [
        [1, 1, 1],
        [2, 2, 2],
        [3, 3, 3],
        [4, 4, 4],
        [5, 5, 5],
        [6, 6, 6],
      ];
    case ScoreSlot.FourOfAKind:
      return [
        [1, 1, 1, 1],
        [2, 2, 2, 2],
        [3, 3, 3, 3],
        [4, 4, 4, 4],
        [5, 5, 5, 5],
        [6, 6, 6, 6],
      ];
    case ScoreSlot.SmallStraight:
      return [[1, 2, 3, 4, 5]];
    case ScoreSlot.BigStraight:
      return [[2, 3, 4, 5, 6]];
    case ScoreSlot.FullHouse:
      return [];
    default:
      return [];
  }
}

function renderSelectionOption(
  dice: DiceNumber[],
  player: Player,
  slot: ScoreSlot,
  key: number | string
) {
  const appState = useAppState();
  return (
    <div
      key={key}
      className={useClasses("selection-option", {
        zero: dice.length === 0,
        big: slot === ScoreSlot.SmallStraight || slot === ScoreSlot.BigStraight,
      })}
      onClick={() => {
        appState.setScore(player, slot, getScoreFromDice(dice));
      }}
    >
      {dice.length === 0 && "Zero"}
      {dice.length > 0 &&
        dice.map((die, index) => <Dice key={index} number={die} />)}
    </div>
  );
}

function TwoColumnSelector({
  player,
  type,
}: {
  player: Player;
  type: ScoreSlot.TwoPairs | ScoreSlot.FullHouse;
}) {
  const appState = useAppState();
  const [columns, setColumns] = useState<Array<DiceNumber | null>>([
    null,
    null,
  ]);
  const [leftColumn, rightColumn] = columns;

  const createColumnHandler =
    (column: "left" | "right", dice: DiceNumber) => () => {
      const newColumns = [
        column === "left" ? dice : leftColumn,
        column === "right" ? dice : rightColumn,
      ];
      setColumns(newColumns);
      if (newColumns[0] !== null && newColumns[1] !== null) {
        if (type === ScoreSlot.TwoPairs) {
          appState.setScore(
            player,
            ScoreSlot.TwoPairs,
            (newColumns[0] + newColumns[1]) * 2
          );
        } else if (type === ScoreSlot.FullHouse) {
          appState.setScore(
            player,
            ScoreSlot.FullHouse,
            newColumns[0] * 2 + newColumns[1] * 3
          );
        }
      }
    };

  const isRowDisabled = (column: "left" | "right", dice: DiceNumber) => {
    if (column === "left" && rightColumn === dice) return true;
    if (column === "right" && leftColumn === dice) return true;
    return false;
  };

  const renderOption = (column: "left" | "right", dice: DiceNumber) => (
    <div
      key={dice}
      className={useClasses("selection-option", {
        selected:
          (column === "left" && leftColumn === dice) ||
          (column === "right" && rightColumn === dice),
        disabled: isRowDisabled(column, dice),
      })}
      onClick={createColumnHandler(column, dice)}
    >
      <Dice number={dice} />
      <Dice number={dice} />
      {column === "right" && type === ScoreSlot.FullHouse && (
        <Dice number={dice} />
      )}
    </div>
  );

  return (
    <div className="selection-list">
      <div className="selection-list-column">
        {[1, 2, 3, 4, 5, 6].map((dice) =>
          renderOption("left", dice as DiceNumber)
        )}
      </div>
      <div className="selection-list-column">
        {[1, 2, 3, 4, 5, 6].map((dice) =>
          renderOption("right", dice as DiceNumber)
        )}
      </div>
    </div>
  );
}

function renderClearAndZero(player: Player, slot: ScoreSlot) {
  const appState = useAppState();
  const showClear = player.score[slot] !== null;
  return (
    <div className="selection-clear-n-zero">
      {showClear && (
        <div
          className="selection-option selection-option--clear"
          onClick={() => {
            appState.setScore(player, slot, null);
          }}
        >
          Clear
        </div>
      )}
      <div
        className="selection-option selection-option--zero"
        onClick={() => {
          appState.setScore(player, slot, 0);
        }}
      >
        Zero
      </div>
    </div>
  );
}

function renderRandomOptions(player: Player) {
  const appState = useAppState();

  const renderButton = (value: number) => (
    <div
      key={value}
      className="selection-option"
      onClick={() => {
        appState.setScore(player, ScoreSlot.Random, value);
      }}
    >
      {value}
    </div>
  );

  return (
    <div className="selection-list-random">
      <div className="selection-list-row">
        {[5, 6, 7, 8, 9, 10].map((value) => renderButton(value))}
      </div>
      <div className="selection-list-row">
        {[11, 12, 13, 14, 15].map((value) => renderButton(value))}
      </div>
      <div className="selection-list-row">
        {[16, 17, 18, 19, 20].map((value) => renderButton(value))}
      </div>
      <div className="selection-list-row">
        {[21, 22, 23, 24, 25].map((value) => renderButton(value))}
      </div>
      <div className="selection-list-row">
        {[26, 27, 28, 29, 30].map((value) => renderButton(value))}
      </div>
    </div>
  );
}

function renderYatzy(player: Player) {
  const appState = useAppState();
  return (
    <div
      className="selection-option selection-option--big selection-option--yatzy"
      onClick={() => {
        appState.setScore(player, ScoreSlot.Yatzy, 50);
      }}
    >
      Yatzy
    </div>
  );
}

function getPosition(
  selectorNode?: Element | null,
  targetNode?: Element | null
): { x: number; y: number } {
  if (selectorNode && targetNode) {
    const selectorBounds = selectorNode.getBoundingClientRect();
    const targetBounds = targetNode.getBoundingClientRect();

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const selectorHeight = selectorBounds.height;
    const selectorWidth = selectorBounds.width;

    let y = targetBounds.bottom + 2;
    if (y + selectorHeight > windowHeight) {
      y = Math.max(0, targetBounds.top - selectorHeight - 2);
    }

    const targetCenter = targetBounds.left + targetBounds.width / 2;
    const x = targetCenter - selectorWidth / 2;

    return {
      x: clamp(x, 0, windowWidth - selectorWidth),
      y,
    };
  }
  return { x: 0, y: 30 };
}

function ScoreSelectorComponent() {
  const appState = useAppState();
  const { scoreSelection } = appState;
  const ref = useRef<HTMLDivElement>(null);
  const positionRef = useRef(getPosition(ref.current, scoreSelection?.target));

  useClickOutside(ref, (e) => {
    appState.clearScoreSelection();
  });
  useEffect(() => {
    if (ref.current) {
      if (ref.current && scoreSelection?.target) {
        positionRef.current = getPosition(ref.current, scoreSelection?.target);
      }
      const { x, y } = positionRef.current;
      ref.current.style.top = `${y}px`;
      ref.current.style.left = `${x}px`;
    }
  });

  const genericSelection =
    scoreSelection?.slot !== ScoreSlot.TwoPairs &&
    scoreSelection?.slot !== ScoreSlot.FullHouse &&
    scoreSelection?.slot !== ScoreSlot.Random;
  scoreSelection?.slot !== ScoreSlot.Yatzy;

  return (
    <div
      ref={ref}
      className={useClasses("score-selector", {
        open: scoreSelection,
      })}
      style={{
        top: `${positionRef.current.y}px`,
        left: `${positionRef.current.x}px`,
      }}
    >
      {scoreSelection &&
        renderClearAndZero(scoreSelection!.player, scoreSelection!.slot)}
      {scoreSelection &&
        genericSelection &&
        getAvailableOptionsPerSlot(scoreSelection.slot).map((dice, index) =>
          renderSelectionOption(
            dice,
            scoreSelection!.player,
            scoreSelection!.slot,
            index
          )
        )}
      {scoreSelection && scoreSelection.slot === ScoreSlot.TwoPairs && (
        <TwoColumnSelector
          player={scoreSelection.player}
          type={ScoreSlot.TwoPairs}
        />
      )}
      {scoreSelection && scoreSelection.slot === ScoreSlot.FullHouse && (
        <TwoColumnSelector
          player={scoreSelection.player}
          type={ScoreSlot.FullHouse}
        />
      )}
      {scoreSelection &&
        scoreSelection.slot === ScoreSlot.Random &&
        renderRandomOptions(scoreSelection.player)}
      {scoreSelection &&
        scoreSelection.slot === ScoreSlot.Yatzy &&
        renderYatzy(scoreSelection.player)}
    </div>
  );
}

export const ScoreSelector = observer(ScoreSelectorComponent);
