import React, { Fragment } from "react";
import { ScoreSlot } from "../types";
import { Dice } from "./Dice";
import "./SlotIcon.scss";
import { QuestionMarkIcon } from "@radix-ui/react-icons";

function getIcon(type: ScoreSlot) {
  switch (type) {
    case ScoreSlot.Ones:
      return <Dice number={1} size="small" />;
    case ScoreSlot.Twos:
      return <Dice number={2} size="small" />;
    case ScoreSlot.Threes:
      return <Dice number={3} size="small" />;
    case ScoreSlot.Fours:
      return <Dice number={4} size="small" />;
    case ScoreSlot.Fives:
      return <Dice number={5} size="small" />;
    case ScoreSlot.Sixes:
      return <Dice number={6} size="small" />;

    case ScoreSlot.UpstairsTotal:
      return "Sum";
    case ScoreSlot.Bonus:
      return "Bonus";

    case ScoreSlot.OnePair:
      return (
        <Fragment>
          <Dice number={6} size="small" />
          <Dice number={6} size="small" />
        </Fragment>
      );
    case ScoreSlot.TwoPairs:
      return (
        <Fragment>
          <Dice number={6} size="small" />
          <Dice number={6} size="small" />
          <Dice number={5} size="small" />
          <Dice number={5} size="small" />
        </Fragment>
      );
    case ScoreSlot.ThreeOfAKind:
      return (
        <Fragment>
          <Dice number={6} size="small" />
          <Dice number={6} size="small" />
          <Dice number={6} size="small" />
        </Fragment>
      );
    case ScoreSlot.FourOfAKind:
      return (
        <Fragment>
          <Dice number={6} size="small" />
          <Dice number={6} size="small" />
          <Dice number={6} size="small" />
          <Dice number={6} size="small" />
        </Fragment>
      );
    case ScoreSlot.SmallStraight:
      return (
        <Fragment>
          <Dice number={1} size="small" />
          <Dice number={2} size="small" />
          <Dice number={3} size="small" />
          <Dice number={4} size="small" />
          <Dice number={5} size="small" />
        </Fragment>
      );
    case ScoreSlot.BigStraight:
      return (
        <Fragment>
          <Dice number={2} size="small" />
          <Dice number={3} size="small" />
          <Dice number={4} size="small" />
          <Dice number={5} size="small" />
          <Dice number={6} size="small" />
        </Fragment>
      );
    case ScoreSlot.FullHouse:
      return (
        <Fragment>
          <Dice number={6} size="small" />
          <Dice number={6} size="small" />
          <Dice number={6} size="small" />
          <Dice number={5} size="small" />
          <Dice number={5} size="small" />
        </Fragment>
      );
    case ScoreSlot.Random:
      return <QuestionMarkIcon />;

    case ScoreSlot.Yatzy:
      return "Yatzy";

    case ScoreSlot.Total:
      return "Total";
    default:
      return null;
  }
}

export function SlotIcon({ type }: { type: ScoreSlot }) {
  return <div className={`slot-icon slot-icon--${type}`}>{getIcon(type)}</div>;
}
