import React from "react";
import { observer } from "mobx-react-lite";
import useClasses from "react-use-classes";
import { useAppState, useSettings } from "../models/store";
import "./Settings.scss";
import { Cross1Icon, GearIcon } from "@radix-ui/react-icons";
import { Setting } from "./Setting";

const SettingsComponent = () => {
  const appState = useAppState();
  const settings = useSettings();
  return (
    <div
      className={useClasses("settings", {
        open: appState.currentOverlay === "settings",
      })}
    >
      <div className="settings-header">
        <h2>Settings</h2>
        <div
          className="settings-close-button"
          onClick={() => {
            appState.setCurrentOverlay(null);
          }}
        >
          <Cross1Icon />
        </div>
      </div>
      <div className="settings-list">
        <Setting
          name="Hide Total during game"
          value={settings.hideTotalDuringGame}
          onSet={(v) => settings.setHideTotalDuringGame(v)}
        />
      </div>
    </div>
  );
};

export const Settings = observer(SettingsComponent);
