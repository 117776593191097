import React, { useRef } from "react";
import "./ScoreCell.scss";
import { Player } from "../models/Player";
import { GameStatus, ScoreSlot, selectableScores } from "../types";
import { useAppState, useSettings } from "../models/store";
import { observer } from "mobx-react-lite";
import { useHold } from "react-haiku";
import useClasses from "react-use-classes";
import { MinusIcon, StarFilledIcon, StarIcon } from "@radix-ui/react-icons";

function getScoreSelectable(type: ScoreSlot) {
  return selectableScores.find((t) => t === type) ? true : false;
}

function ScoreCellComponent({
  player,
  type,
}: {
  player: Player;
  type: ScoreSlot;
}) {
  const appState = useAppState();
  const settings = useSettings();
  const ref = useRef<HTMLDivElement>(null);
  const isSelectable = getScoreSelectable(type);
  const selectScore = () => {
    if (isSelectable && player.score[type] === null) {
      appState.setScoreSelection(player, type, ref.current!);
    }
  };
  const isSelected =
    appState.scoreSelection?.player === player &&
    type == appState.scoreSelection.slot;

  const holdProps = useHold(
    () => {
      if (player.score[type] !== null && isSelectable) {
        appState.setScoreSelection(player, type, ref.current!);
      }
    },
    {
      doPreventDefault: false,
      delay: 500,
    }
  );
  const score = player.score[type];
  const upstrairsDiff = player.score.upstairsDiff;
  return (
    <div
      ref={ref}
      className={useClasses("score-cell", {
        [type]: true,
        selected: isSelected,
        filled: player.score[type] !== null && getScoreSelectable(type),
        selectable: getScoreSelectable(type),
        disabled: appState.gameState.status === GameStatus.Over,
        gold:
          type === ScoreSlot.Total && player === appState.gameState.firstPlace,
        silver:
          type === ScoreSlot.Total && player === appState.gameState.secondPlace,
        bronze:
          type === ScoreSlot.Total && player === appState.gameState.thirdPlace,
        hide:
          settings.hideTotalDuringGame &&
          type === ScoreSlot.Total &&
          appState.gameState.status !== GameStatus.Over,
      })}
      onClick={() => {
        selectScore();
      }}
      {...holdProps}
    >
      <span className="score-cell-score">
        {score === null && ""}
        {score === 0 && isSelectable && <MinusIcon />}
        {score !== null && score > 0 && score}
      </span>
      {type === ScoreSlot.UpstairsTotal && upstrairsDiff !== 0 && (
        <span
          className={useClasses("score-cell-diff", {
            positive: upstrairsDiff > 0,
            negative: upstrairsDiff < 0,
          })}
        >{`${upstrairsDiff > 0 ? "+" : ""}${upstrairsDiff}`}</span>
      )}
    </div>
  );
}

export const ScoreCell = observer(ScoreCellComponent);
