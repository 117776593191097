import { makeAutoObservable } from "mobx";
import { ScoreSlot } from "../types";
import { GameState } from "./GameState";
import { Player } from "./Player";

interface ScoreSelection {
  player: Player;
  slot: ScoreSlot;
  target: Element;
}

type OverlayType = "highscores" | "settings";

export class AppState {
  public gameState = new GameState();
  public scoreSelection: ScoreSelection | null = null;

  public currentOverlay: OverlayType | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  public setScoreSelection(player: Player, slot: ScoreSlot, target: Element) {
    this.scoreSelection = {
      player,
      slot,
      target,
    };
  }

  public clearScoreSelection() {
    this.scoreSelection = null;
  }

  public setScore(player: Player, slot: ScoreSlot, value: number | null) {
    player.score.setScore(slot, value);
    this.gameState.nextPlayer();
    this.clearScoreSelection();
    this.gameState.saveToLocalStorage();
  }

  public setCurrentOverlay(overlay: OverlayType | null) {
    this.currentOverlay = overlay;
  }
}
