import { observer } from "mobx-react-lite";
import { Player } from "../models/Player";
import React, { useRef, useState } from "react";
import "./HeaderPlayer.scss";
import { useClickOutside } from "react-haiku";
import useClasses from "react-use-classes";
import { useAppState, useSettings } from "../models/store";
import { AutoTextSize } from "auto-text-size";
import { NameAutoComplete } from "./NameAutoComplete";
import { GameStatus } from "../types";
import { StarFilledIcon } from "@radix-ui/react-icons";

function HeaderPlayerComponent({ player }: { player: Player }) {
  const appState = useAppState();
  const settings = useSettings();
  const [editing, setEditing] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState(player.name);

  useClickOutside(inputRef, () => {
    setEditing(false);
    if (name.length > 0) {
      appState.gameState.renamePlayer(player, name);
    } else {
      setName(player.name);
    }
  });

  return (
    <div
      className={useClasses("header-player", {
        editing,
        first:
          (!settings.hideTotalDuringGame ||
            appState.gameState.status === GameStatus.Over) &&
          player === appState.gameState.firstPlace,
      })}
      ref={ref}
      onClick={() => {
        if (!appState.gameState.isGameOver) {
          setEditing(true);
        }
      }}
    >
      {!editing && (
        <AutoTextSize
          className="header-player-name"
          maxFontSizePx={16}
          minFontSizePx={8}
        >
          {player.name}
        </AutoTextSize>
      )}
      {editing && (
        <input
          ref={inputRef}
          type="text"
          className="header-player-name-input"
          value={name}
          onFocus={(e) => {
            e.target.select();
          }}
          autoFocus
          onChange={(el) => {
            setName(el.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Escape") {
              setEditing(false);
              appState.gameState.renamePlayer(player, name);
            }
          }}
          onBlur={() => {
            appState.gameState.renamePlayer(player, name);
            setEditing(false);
          }}
        />
      )}
      {editing && (
        <NameAutoComplete
          parentEl={ref.current}
          currentName={name}
          setName={(n) => {
            appState.gameState.renamePlayer(player, n);
            setName(n);
            setEditing(false);
          }}
        />
      )}
      {(!settings.hideTotalDuringGame ||
        appState.gameState.status === GameStatus.Over) &&
        appState.gameState.firstPlace === player &&
        player.score.total !== null &&
        player.score.total > 0 && (
          <StarFilledIcon className="header-player-star" />
        )}
    </div>
  );
}

export const HeaderPlayer = observer(HeaderPlayerComponent);
