import { observer } from "mobx-react-lite";
import { useAppState } from "../models/store";
import React, { useMemo, useState } from "react";
import { Cross1Icon } from "@radix-ui/react-icons";
import useClasses from "react-use-classes";
import "./Highscores.scss";

type SortingOptions =
  | "name"
  | "highscore"
  | "yatzys"
  | "games"
  | "wins"
  | "lowscore"
  | "winPercentage";

interface SortingOptionDef {
  title: string;
  option: SortingOptions;
}

const headerOptions: SortingOptionDef[] = [
  {
    title: "Highscore",
    option: "highscore",
  },
  {
    title: "Games",
    option: "games",
  },
  {
    title: "Wins",
    option: "wins",
  },
  {
    title: "Win%",
    option: "winPercentage",
  },
  {
    title: "Yatzys",
    option: "yatzys",
  },
  {
    title: "Lowscore",
    option: "lowscore",
  },
  {
    title: "Name",
    option: "name",
  },
];

function HighscoreHeader({
  current,
  onChange,
}: {
  current: SortingOptions;
  onChange: (option: SortingOptions) => void;
}) {
  return (
    <div className="highscores-header">
      {headerOptions.map(({ title, option }) => (
        <div
          key={option}
          className={useClasses("highscores-header-option", {
            current: option === current,
          })}
          onClick={(e) => {
            onChange(option);
            (e.target as HTMLDivElement).scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          {title}
        </div>
      ))}
    </div>
  );
}

function HighscoresComponent() {
  const appState = useAppState();
  const [sorting, setSorting] = useState<SortingOptions>("highscore");

  useMemo(() => {
    if (sorting === "name") {
      appState.gameState.highscores.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    } else if (sorting === "lowscore") {
      appState.gameState.highscores.sort("lowscore", "asc");
    } else {
      appState.gameState.highscores.sort(sorting, "desc");
    }
  }, [sorting]);

  return (
    <div
      className={useClasses("highscores", {
        open: appState.currentOverlay === "highscores",
      })}
    >
      <div className="highscore-title">
        <h2>Highscores</h2>
        <div
          className="highscore-close-button"
          onClick={() => {
            appState.setCurrentOverlay(null);
          }}
        >
          <Cross1Icon />
        </div>
      </div>
      <HighscoreHeader current={sorting} onChange={(o) => setSorting(o)} />
      <div className="highscore-list">
        {appState.gameState.highscores.highscores.map((player, index) => (
          <div key={index} className="highscore-entry">
            <div className="highscore-entry-column highscore-entry-column-name">
              {player.name}
            </div>
            <div className="highscore-entry-column highscore-entry-column-right-aligned">
              {sorting === "name"
                ? player.highscore
                : sorting === "winPercentage"
                ? `${player.winPercentage.toFixed(
                    player.winPercentage % 1 === 0 ? 0 : 1
                  )}% (${player.wins}/${player.games})`
                : player[sorting]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export const Highscores = observer(HighscoresComponent);
