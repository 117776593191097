import { makeAutoObservable } from "mobx";
import { Score } from "./Score";

export class Player {
  public name = "";

  public score = new Score();

  constructor(name: string) {
    this.name = name;
    makeAutoObservable(this);
  }

  public setName(name: string) {
    this.name = name;
  }

  public deserialize(obj: any) {
    this.name = obj.name;
    this.score.deserialize(obj.score);
  }
}
