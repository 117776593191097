import React, { useRef, useState } from "react";
import { useAppState } from "../models/store";
import {
  GearIcon,
  HamburgerMenuIcon,
  ListBulletIcon,
  PlusIcon,
  ReloadIcon,
} from "@radix-ui/react-icons";
import { observer } from "mobx-react-lite";
import "./Header.scss";
import useClasses from "react-use-classes";
import { useClickOutside } from "react-haiku";
import { GameStatus } from "../types";

function HeaderComponent() {
  const appState = useAppState();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);
  useClickOutside(menuRef, (e) => {
    if (e.target === menuButtonRef.current) return;
    setMenuOpen(false);
  });
  return (
    <div className="board-row header">
      <div
        ref={menuButtonRef}
        className={`header-button header-menu-button ${
          menuOpen ? "header-menu-button--open" : ""
        }`}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        <HamburgerMenuIcon />
      </div>
      <div
        ref={menuRef}
        className={useClasses("header-menu", { open: menuOpen })}
      >
        <div
          className="header-menu-option"
          onClick={() => {
            appState.gameState.restartGame();
            setMenuOpen(false);
          }}
        >
          <ReloadIcon />
          New Game (same players)
        </div>
        <div
          className="header-menu-option"
          onClick={() => {
            appState.gameState.freshGame();
            setMenuOpen(false);
          }}
        >
          <PlusIcon />
          Fresh Game
        </div>
        <div
          className="header-menu-option"
          onClick={() => {
            appState.setCurrentOverlay("highscores");
            setMenuOpen(false);
          }}
        >
          <ListBulletIcon />
          Highscores
        </div>
        <div
          className="header-menu-option"
          onClick={() => {
            appState.setCurrentOverlay("settings");
            setMenuOpen(false);
          }}
        >
          <GearIcon />
          Settings
        </div>
      </div>
      <div
        className={`header-button header-add-player-button ${
          appState.gameState.status === GameStatus.Over
            ? "header-add-player-button--disabled"
            : ""
        }`}
        onClick={() => {
          if (!appState.gameState.isGameOver) {
            appState.gameState.addPlayer(
              `Player ${appState.gameState.playerCount + 1}`
            );
          }
        }}
      >
        <PlusIcon />
      </div>
    </div>
  );
}

export const Header = observer(HeaderComponent);
