export enum ScoreSlot {
  Ones = "ones",
  Twos = "twos",
  Threes = "threes",
  Fours = "fours",
  Fives = "fives",
  Sixes = "sixes",

  UpstairsTotal = "upstairsTotal",
  Bonus = "bonus",

  OnePair = "onePair",
  TwoPairs = "twoPairs",
  ThreeOfAKind = "threeOfAKind",
  FourOfAKind = "fourOfAKind",
  SmallStraight = "smallStraight",
  BigStraight = "bigStraight",
  FullHouse = "fullHouse",
  Random = "random",

  Yatzy = "yatzy",

  Total = "total",
}

export type DiceNumber = 1 | 2 | 3 | 4 | 5 | 6;

export const selectableScores = [
  ScoreSlot.Ones,
  ScoreSlot.Twos,
  ScoreSlot.Threes,
  ScoreSlot.Fours,
  ScoreSlot.Fives,
  ScoreSlot.Sixes,
  ScoreSlot.OnePair,
  ScoreSlot.TwoPairs,
  ScoreSlot.ThreeOfAKind,
  ScoreSlot.FourOfAKind,
  ScoreSlot.SmallStraight,
  ScoreSlot.BigStraight,
  ScoreSlot.FullHouse,
  ScoreSlot.Random,
  ScoreSlot.Yatzy,
];

export enum GameStatus {
  Playing,
  Over,
}
