import { makeAutoObservable } from "mobx";
import { ScoreSlot, selectableScores } from "../types";
import { addArray, contains } from "../util/array";

const {
  Ones,
  Twos,
  Threes,
  Fours,
  Fives,
  Sixes,
  OnePair,
  TwoPairs,
  ThreeOfAKind,
  FourOfAKind,
  SmallStraight,
  BigStraight,
  FullHouse,
  Random,
  Yatzy,
} = ScoreSlot;

export class Score {
  // Upstairs
  public [Ones]: null | number = null;
  public [Twos]: null | number = null;
  public [Threes]: null | number = null;
  public [Fours]: null | number = null;
  public [Fives]: null | number = null;
  public [Sixes]: null | number = null;

  // Downstairs
  public [OnePair]: null | number = null;
  public [TwoPairs]: null | number = null;
  public [ThreeOfAKind]: null | number = null;
  public [FourOfAKind]: null | number = null;
  public [SmallStraight]: null | number = null;
  public [BigStraight]: null | number = null;
  public [FullHouse]: null | number = null;
  public [Random]: null | number = null;

  public [Yatzy]: null | number = null;

  constructor() {
    makeAutoObservable(this);
  }

  public get upstairsTotal(): number {
    const { ones, twos, threes, fours, fives, sixes } = this;
    const scores = [ones, twos, threes, fours, fives, sixes];
    return addArray(scores.map((v) => (v ? v : 0)));
  }

  public get bonus(): number | null {
    const { ones, twos, threes, fours, fives, sixes } = this;
    const scores = [ones, twos, threes, fours, fives, sixes];
    if (scores.find((v) => v === null) === null) {
      return null;
    }
    return this.upstairsTotal >= 63 ? 50 : 0;
  }

  public get projectedUpstairsTotal() {
    let total = 0;
    const { ones, twos, threes, fours, fives, sixes } = this;
    total += ones === null ? 3 : ones;
    total += twos === null ? 6 : twos;
    total += threes === null ? 9 : threes;
    total += fours === null ? 12 : fours;
    total += fives === null ? 15 : fives;
    total += sixes === null ? 18 : sixes;
    return total;
  }

  public get upstairsDiff() {
    return this.projectedUpstairsTotal - 63;
  }

  public get total(): number {
    const {
      ones,
      twos,
      threes,
      fours,
      fives,
      sixes,

      bonus,

      onePair,
      twoPairs,
      threeOfAKind,
      fourOfAKind,
      smallStraight,
      bigStraight,
      fullHouse,
      random,

      yatzy,
    } = this;
    return addArray(
      [
        ones,
        twos,
        threes,
        fours,
        fives,
        sixes,

        bonus,

        onePair,
        twoPairs,
        threeOfAKind,
        fourOfAKind,
        smallStraight,
        bigStraight,
        fullHouse,
        random,

        yatzy,
      ].map((v) => (v === null ? 0 : v))
    );
  }

  public setScore(slot: ScoreSlot, value: number | null) {
    if (contains(selectableScores, slot)) {
      this[slot as any] = value;
    }
  }

  public get missingScores() {
    return addArray(
      selectableScores.map((type) => (this[type as any] === null ? 1 : 0))
    );
  }

  public reset() {
    this.ones = null;
    this.twos = null;
    this.threes = null;
    this.fours = null;
    this.fives = null;
    this.sixes = null;

    this.onePair = null;
    this.twoPairs = null;
    this.threeOfAKind = null;
    this.fourOfAKind = null;
    this.smallStraight = null;
    this.bigStraight = null;
    this.fullHouse = null;
    this.random = null;

    this.yatzy = null;
  }

  public deserialize(obj: any) {
    this.ones = obj.ones;
    this.twos = obj.twos;
    this.threes = obj.threes;
    this.fours = obj.fours;
    this.fives = obj.fives;
    this.sixes = obj.sixes;

    this.onePair = obj.onePair;
    this.twoPairs = obj.twoPairs;
    this.threeOfAKind = obj.threeOfAKind;
    this.fourOfAKind = obj.fourOfAKind;
    this.smallStraight = obj.smallStraight;
    this.bigStraight = obj.bigStraight;
    this.fullHouse = obj.fullHouse;
    this.random = obj.random;

    this.yatzy = obj.yatzy;
  }
}
