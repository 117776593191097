interface PlayerScoreSnapshot {
  name: string;
  yatzy: boolean;
  total: number;
}

interface PlayerHighscoreEntry {
  name: string;
  highscore: number;
  yatzys: number;
  games: number;
  wins: number;
  lowscore: number;
  winPercentage: number;
}

type GameSnapshot = PlayerScoreSnapshot[];

export class Highscores {
  games: GameSnapshot[] = [];
  private playersToGamesMap: Record<string, GameSnapshot[]> = {};
  public highscores: PlayerHighscoreEntry[] = [];
  public playerList: string[] = [];

  constructor() {
    const existingState = localStorage.getItem("highscores");
    if (existingState) {
      try {
        const data = JSON.parse(existingState);
        this.games = data;
      } catch (e) {
        console.warn(e);
      }
    }
    this.update();
  }

  public addGame(snapshot: GameSnapshot) {
    this.games.push(snapshot);
    this.update();
  }

  private updatePlayersToGamesMap() {
    this.playersToGamesMap = {};
    this.games.forEach((game) => {
      game.forEach((player) => {
        if (this.playersToGamesMap[player.name]) {
          this.playersToGamesMap[player.name].push(game);
        } else {
          this.playersToGamesMap[player.name] = [game];
        }
      });
    });
  }

  private updateHighscores() {
    this.highscores = [];
    Object.keys(this.playersToGamesMap).forEach((name) => {
      const gameSnapshots = this.playersToGamesMap[name];
      const highScoreEntry: PlayerHighscoreEntry = {
        name,
        highscore: 0,
        yatzys: 0,
        games: 0,
        wins: 0,
        lowscore: 1000,
        winPercentage: 0,
      };
      highScoreEntry.games = gameSnapshots.length;
      gameSnapshots.forEach((players) => {
        const playerSnapshot = players.find(
          (playerSnapshot) => playerSnapshot.name == name
        );
        if (playerSnapshot) {
          if (highScoreEntry.highscore < playerSnapshot.total) {
            highScoreEntry.highscore = playerSnapshot.total;
          }
          if (highScoreEntry.lowscore > playerSnapshot.total) {
            highScoreEntry.lowscore = playerSnapshot.total;
          }
          if (playerSnapshot.yatzy) {
            highScoreEntry.yatzys += 1;
          }
          if (!players.find((player) => player.total > playerSnapshot.total)) {
            highScoreEntry.wins += 1;
          }
          highScoreEntry.winPercentage =
            (highScoreEntry.wins / highScoreEntry.games) * 100;
        }
      });
      this.highscores.push(highScoreEntry);
    });

    this.highscores.sort((a, b) => b.highscore - a.highscore);
  }

  private updatePlayerList() {
    const newList: string[] = [];
    this.games.forEach((players) => {
      players.forEach(({ name }) => {
        const index = newList.indexOf(name);
        if (index > -1) {
          newList.splice(index, 1);
        }
        newList.push(name);
      });
    });
    newList.reverse();
    this.playerList = newList;
  }

  public update() {
    this.updatePlayersToGamesMap();
    this.updatePlayerList();
    this.updateHighscores();
  }

  public saveToLocalStorage() {
    localStorage.setItem("highscores", JSON.stringify(this.games));
  }

  public sort(
    property: keyof PlayerHighscoreEntry,
    direction: "asc" | "desc"
  ): void;
  public sort(
    sorter: (a: PlayerHighscoreEntry, b: PlayerHighscoreEntry) => number
  ): void;
  public sort(
    propertyOrSorter:
      | keyof PlayerHighscoreEntry
      | ((a: PlayerHighscoreEntry, b: PlayerHighscoreEntry) => number),
    direction?: "asc" | "desc"
  ) {
    if (typeof propertyOrSorter === "string") {
      this.highscores.sort((a, b) =>
        direction === "asc"
          ? (a[propertyOrSorter] as number) - (b[propertyOrSorter] as number)
          : (b[propertyOrSorter] as number) - (a[propertyOrSorter] as number)
      );
    } else {
      this.highscores.sort(propertyOrSorter);
    }
  }
}
