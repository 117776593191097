export function addArray(arr: number[]): number {
  let total = 0;
  for (let i = 0; i < arr.length; i++) {
    total += arr[i];
  }
  return total;
}

export function contains<T>(arr: T[], value: T): boolean {
  return !!arr.find((v) => value);
}
