import React from "react";
import "./Setting.scss";
import useClasses from "react-use-classes";

const ToggleComponent = ({
  value,
  onToggle,
}: {
  value: boolean;
  onToggle: (value: boolean) => void;
}) => {
  return (
    <div
      className={useClasses("setting-toggle", {
        on: value,
      })}
      onClick={() => onToggle(!value)}
    >
      <div className="setting-toggle-knob" />
    </div>
  );
};

const SettingComponent = ({
  name,
  value,
  onSet,
}: {
  name: string;
  value: boolean;
  onSet: (v: boolean) => void;
}) => {
  return (
    <div className="setting">
      <div className="setting-name">{name}</div>
      <div className="setting-value">
        <ToggleComponent value={value} onToggle={onSet} />
      </div>
    </div>
  );
};

export const Setting = SettingComponent;
